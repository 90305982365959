import React from "react"
import Layout from "../components/layouts/Layout.js"
import * as styles from "../stylesheets/pages/onboard.module.scss"
import OnboardForm from "../components/onboardForm.js"

const HardwareQuote = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <div style={{ margin: "auto", width: "640px" }}>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSceUGWImUuDcz3HWirPQohasAn4bkXSNvVvxdhgCb7WtpFXKA/viewform?embedded=true"
            frameborder="0"
            style={{ display: "block" }}
            className={styles.form}
          >
            Loading…
          </iframe>
        </div>
      </div>
    </Layout>
  )
}

export default HardwareQuote
